<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>小程序设置</span>
			</div>
			<el-tabs v-model="preUrl" @tab-click="tabClick">
				<el-tab-pane label="首页数据" name="image">
					<el-button type="primary" size="small" @click="showForm('image')">新增数据</el-button>
					<el-table ref="multipleTable" :data="imageList" tooltip-effect="dark" style="width: 100%">
						<el-table-column prop="group_text" label="分类">
						</el-table-column>
						<el-table-column prop="img" label="图片">
							<template slot-scope="scope">
								<el-image style="width: 100px;" :src="$conf.serverUrl+'storage/'+scope.row.img"></el-image>
							</template>
						</el-table-column>
						<el-table-column prop="text" label="名称">
						</el-table-column>
						<el-table-column prop="article_url" label="文章链接">
						</el-table-column>
						<el-table-column prop="order" label="排序">
						</el-table-column>
						<el-table-column prop="create_time" label="创建时间" width="100">
						</el-table-column>
						<el-table-column prop="operate" label="操作">
							<template slot-scope="scope">
								<el-button type="text" size="mini" @click="showForm('image',scope.row)">编辑</el-button>
								<el-button type="text" size="mini" class="color-danger" @click="deleteClick(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange"
						:current-page="pageData.current" :page-sizes="pageData.sizes" :page-size="pageData.size"
						layout="total, sizes, prev, pager, next, jumper" :total="pageData.total" class="mt-3">
					</el-pagination>
				</el-tab-pane>
				<el-tab-pane label="运行配置" name="running">
					<el-form ref="runRuleForm" :model="miniprogramconfig" :label-width="formLabelWidth" :rules="runRules">
						<el-form-item label="小程序appid" prop="app_id">
							<el-input v-model="miniprogramconfig.app_id" autocomplete="off" size="small" style="width: 80%;">
							</el-input>
						</el-form-item>
						<el-form-item label="小程序appSecret" prop="app_secret">
							<el-input v-model="miniprogramconfig.app_secret" autocomplete="off" size="small" style="width: 80%;">
							</el-input>
						</el-form-item>
					</el-form>
					<div class="text-center">
						<el-button type="primary" @click="submitRunning">保存</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		<el-dialog :title="formTitle" :visible.sync="formVisible">
			<!-- 添加/修改图片 -->
			<el-form v-if="preUrl == 'image'" :model="formData" :rules="imageRules" ref="imageRuleForm">
				<el-form-item label="分类" :label-width="formLabelWidth" prop="group">
					<el-select v-model="formData.group" placeholder="请选择分类" size="small">
						<el-option v-for="item in typeList" :label="item.name" :value="item.id" :key="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="名称" :label-width="formLabelWidth" prop="text">
					<el-input v-model="formData.text" autocomplete="off" size="small" style="width: 80%;" placeholder="请输入名称">
					</el-input>
				</el-form-item>
				<el-form-item label="文章链接" :label-width="formLabelWidth" prop="article_url">
					<el-input v-model="formData.article_url" autocomplete="off" size="small" style="width: 80%;"
						placeholder="请输入文章链接"></el-input>
				</el-form-item>
				<el-form-item label="排序" :label-width="formLabelWidth" prop="order">
					<el-input v-model="formData.order" autocomplete="off" size="small" style="width: 80%;"
						placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="图片" :label-width="formLabelWidth" prop="img">
					<div class="el-upload-list--picture-card d-flex" style="flex-wrap: wrap;">
						<div class="el-upload-list__item" style="position: relative;" v-if="formData.img">
							<el-image class="el-upload-list__item-thumbnail" :src="$conf.serverUrl+'storage/'+formData.img"></el-image>
							<div class="d-flex align-items-center justify-content-center p-1" style="position: absolute; top:0;right: 0;"
								 @click="deleteImg(formData.img)">
								 <i class="el-icon-error text-white" style="font-size: 20px;"></i>
							</div>
						</div>
						<el-upload action="/admin/service/uploadimg" v-if="!formData.img"
								   :http-request="diyUpload"
								   :headers="{token:$store.state.user.token}"
								   name="image[]"
								   :show-file-list="false"
								   list-type="picture-card">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancelClick">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import operateSearch from "@/components/common/operate-search.vue"
	import common from "@/common/mixins/common.js"
	export default {
		components: {
			operateSearch
		},
		mixins: [common],
		inject: ['layout'],
		data() {
			return {
				preUrl: 'image',
				imageList: [],
				formItemId:0,
				formTitle: '',
				formVisible: false,
				formType: 'image',
				formData: {
					img:'',
					text:'',
					group:0,
					article_url:'',
					order:0
				},
				typeList:[
					{name:'未定义',id:0},
					{name:'Banner图',id:1},
					{name:'导航栏',id:2}
				],
				imageRules: {
					img: [{
						required: true,
						message: '请上传图片',
						trigger: 'blur'
					}, ]
				},
				runRules: {
					app_id: [{
						required: true,
						message: '请填写app_id',
						trigger: 'blur'
					}, ],
					app_secret: [{
						required: true,
						message: '请填写app_secret',
						trigger: 'blur'
					}, ]
				},
				formLabelWidth: '120px',
				miniprogramconfig:{},
				preImg:''
			};
		},
		methods: {
			getListRes(e) {
				switch (this.preUrl) {
					case 'image':
						this.imageList = e.list
						break;
					case 'running':
						this.miniprogramconfig = e
						break;
					default:
						break;
				}
			},
			getUrl() {
				switch (this.preUrl) {
					case 'image':
						return `/admin/${this.preUrl}/index/${this.pageData.current}?limit=${this.pageData.size}`
						break;
					case 'running':
						return `/admin/config/getconfig`
						break;
					default:
						break;
				}
			},
			diyUpload(file) {
				let fileData = new FormData()
				fileData.append('image', file.file);
				let url = file.action
				this.axios.post(url, fileData,{
						token: true
					})
					.then((res) => {
						this.formData.img = res.data.data.imgs
						this.preImg = res.data.data.imgs
					})
					.catch((err) => {
						console.log(err)
					})
			},
			tabClick() {
				this.getList()
			},
			showForm(type, data = false) {
				this.formType = type
				this.formItemId = data ? data.id : 0
				this.formTitle = data ? '修改' : '添加'
				this.formData = {
					text: data ? data.text : '',
					article_url: data ? data.article_url : '',
					img: data ? data.img : '',
					order: data ? data.order : 0,
					group: data ? data.group : 0,
				}
				this.formVisible = true
			},
			submit() {
				this.$refs['imageRuleForm'].validate((valid) => {
					if(valid){
						this.addOrEdit(this.formData, this.formItemId)
						this.formVisible = false
						this.preImg = ''
					}
				})
			},
			cancelClick(){
				if(this.preImg){
					this.deleteImg(this.preImg)
				}
				this.formVisible = false
			},
			deleteImg(img){
				let url = '/admin/service/deleteimg'
				this.axios.post(url, {delimgs:[img]}, {
					token: true
				})
				.then((res) => {
					this.formData.img = ''
				})
				.catch((err) => {
					console.log(err)
				})
			},
			deleteClick(row){
				this.deleteImg(row.img)
				this.deleteItem(row)
			},
			submitRunning(){
				this.$refs['runRuleForm'].validate((valid) => {
					if(valid){
						let url = '/admin/config/setconfig'
						this.axios.post(url, this.miniprogramconfig, {
							token: true
						})
						.then((res) => {
							this.miniprogramconfig = res.data.data.config
							this.$message({
								message: '保存成功',
								type: 'success'
							})
						})
						.catch((err) => {
							console.log(err)
							this.$message.error({
								message: '保存失败'
							})
						})
					}
				})
			}
		}
	};
</script>

<style>
</style>
